















import Vue from 'vue';

export default Vue.extend({
  name: "EmploymentMain2d",
  data: () => {
    return {};
  },
  methods: {
    async opener(link) {
      window.open(link, "targetWindow", "height=650,width=800,menubar=no,status=no");
    },
  },
});
